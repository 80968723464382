// components
import Seo from 'src/components/Seo';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={'Privacy Policy - Seedium'}
      description={
        'Check out the Privacy Policy to learn what information of yours will be collected by Seedium when you access our website.'
      }
      schema={{
        schemaContext: 'https://schema.org',
        schemaType: 'WebPage',
        schemaName: 'Privacy Policy - Seedium',
        schemaDescription:
          'Check out the Privacy Policy to learn what information of yours will be collected by Seedium when you access our website.',
        schemaUrl: 'https://seedium.io',
        schemaLogo: 'https://seedium.io/images/logo.png',
      }}
    />
  );
};

export { default } from './PrivacyPolicy';
