import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Content from './sections/Content';
import { PageProps } from 'gatsby';

const PrivacyPolicy: FC<PageProps> = () => {
  return (
    <PageLayout>
      <Intro
        title='Privacy Policy'
        description='Our Privacy Policy determines the procedure for receiving, storing, processing, and using information obtained during the use of the site.'
        icon='privacyPolicyDrawing'
      />
      <Content />
    </PageLayout>
  );
};

export default PrivacyPolicy;
